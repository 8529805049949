import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`12th June 2024`}</p>


    <h2 {...{
      "id": "-new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-new-features",
        "aria-label": " new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🌟 New features`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Armeria `}<a parentName="p" {...{
            "href": "https://cloud.google.com/endpoints/docs/grpc/transcoding"
          }}>{`HTTP/JSON to GRPC transcoding`}</a>{` service now
supports `}<a parentName="p" {...{
            "href": "https://cloud.google.com/apis/design/custom_methods"
          }}>{`Custom methods`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5613"
          }}>{`#5613`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-protobuf"
          }}>{`rpc Watch(WatchRequest) returns (WatchResponse) {
  option (google.api.http) = {
    post: "/v1:watch" // 👈👈👈
    body: "*"
  };
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now specify when to remove multipart temporary files using `}<a parentName="p" {...{
            "href": "type://MultipartRemovalStrategy:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/MultipartRemovalStrategy.html"
          }}>{`type://MultipartRemovalStrategy`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5652"
          }}>{`#5652`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5653"
          }}>{`#5653`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Server
  .builder()
  .multipartRemovalStrategy(
    MultipartRemovalStrategy.ON_RESPONSE_COMPLETION)
  ...
`}</code></pre>
        <ul parentName="li">
          <li parentName="ul">{`The default value is now `}<a parentName="li" {...{
              "href": "type://MultipartRemovalStrategy#ON_RESPONSE_COMPLETION:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/MultipartRemovalStrategy.html#ON_RESPONSE_COMPLETION"
            }}>{`type://MultipartRemovalStrategy#ON_RESPONSE_COMPLETION`}</a>{` which removes
the temporary files when the response is completed.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`A `}<a parentName="p" {...{
            "href": "type://ClientConnectionTimings:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/ClientConnectionTimings.html"
          }}>{`type://ClientConnectionTimings`}</a>{` now includes the time spent on the TLS handshake. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3647"
          }}>{`#3647`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5647"
          }}>{`#5647`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ClientConnectionTimings timings = ...
assert timings.tlsHandshakeDurationNanos() > 0;
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now configure `}<a parentName="p" {...{
            "href": "type://TlsEngineType:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/util/TlsEngineType.html"
          }}>{`type://TlsEngineType`}</a>{` using `}<a parentName="p" {...{
            "href": "type://ClientFactoryBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientFactoryBuilder.html"
          }}>{`type://ClientFactoryBuilder`}</a>{` or `}<a parentName="p" {...{
            "href": "type://ServerBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerBuilder.html"
          }}>{`type://ServerBuilder`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4962"
          }}>{`#4962`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ClientFactory
  .builder()
  .tlsEngineType(TlsEngineType.OPENSSL) // 👈👈👈
  .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now easily find both dynamic and static decorators that handle a request
via `}<a parentName="p" {...{
            "href": "type://ServiceRequestContext#findService(Class):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceRequestContext.html#findService(java.lang.Class)"
          }}>{`type://ServiceRequestContext#findService(Class)`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5670"
          }}>{`#5670`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ServerBuilder sb = ...
sb.decorator(CorsService.builderForAnyOrigin().newDecorator());
...
ServiceRequestContext ctx = ...
CorsService corsService = ctx.findService(CorsService.class);
assert corsService.config().isAnyOriginSupported();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now use the marshaller specified by gRPC `}<inlineCode parentName="p">{`MethodDescriptor`}</inlineCode>{` by setting
`}<a parentName="p" {...{
            "href": "type://GrpcClientBuilder#useMethodMarshaller(boolean):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/grpc/GrpcClientBuilder.html#useMethodMarshaller(boolean)"
          }}>{`type://GrpcClientBuilder#useMethodMarshaller(boolean)`}</a>{` and
`}<a parentName="p" {...{
            "href": "type://GrpcServiceBuilder#useMethodMarshaller(boolean):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcServiceBuilder.html#useMethodMarshaller(boolean)"
          }}>{`type://GrpcServiceBuilder#useMethodMarshaller(boolean)`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5103"
          }}>{`#5103`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5630"
          }}>{`#5630`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`GrpcClientBuilder builder = ...
builder.useMethodMarshaller(true); // 👈👈👈

GrpcServiceBuilder builder = ...
builder.useMethodMarshaller(true); // 👈👈👈
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now programmatically retrieve the server side metrics via `}<a parentName="p" {...{
            "href": "type://ServerMetrics:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerMetrics.html"
          }}>{`type://ServerMetrics`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4992"
          }}>{`#4992`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5627"
          }}>{`#5627`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ServerMetrics metrics = serverConfig.serverMetrics();
metrics.activeConnections();
metrics.pendingRequests();
metrics.activeRequests();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now use a `}<a parentName="p" {...{
            "href": "type://CoroutineHttpService"
          }}>{`type://CoroutineHttpService`}</a>{` that runs your service in a coroutine scope. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5442"
          }}>{`#5442`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5603"
          }}>{`#5603`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-kotlin"
          }}>{`ServerBuilder sb = ...
sb.service(
  "/hello",
  CoroutineHttpService { ctx, req ->
    HttpResponse.of("hello world")
  })
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now specify options for an `}<a parentName="p" {...{
            "href": "type://HttpService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/HttpService.html"
          }}>{`type://HttpService`}</a>{` via `}<a parentName="p" {...{
            "href": "type://ServiceOptions:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceOptions.html"
          }}>{`type://ServiceOptions`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5071"
          }}>{`#5071`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5574"
          }}>{`#5574`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`static final ServiceOptions SERVICE_OPTIONS =
  ServiceOptions
    .builder()
    .requestTimeoutMillis(5000)
    .maxRequestLength(1024)
    .requestAutoAbortDelayMillis(1000)
    .build();

HttpService httpService = new HttpService() {
  ...
  @Override
  public ServiceOptions options() {
    return SERVICE_OPTIONS;
  }
};

// Or use annotation for an annotated service.
class MyService {
    @ServiceOption(requestTimeoutMillis = 5000, maxRequestLength = 1024)
    @Get("/hello")
    public HttpResponse hello() {
        return HttpResponse.of("Hello!");
    }
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now inject a custom attribute from a `}<a parentName="p" {...{
            "href": "type://ServiceRequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceRequestContext.html"
          }}>{`type://ServiceRequestContext`}</a>{` to an annotated service
using `}<a parentName="p" {...{
            "href": "type://@Attribute:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Attribute.html"
          }}>{`type://@Attribute`}</a>{` annotation. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5514"
          }}>{`#5514`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5547"
          }}>{`#5547`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`class MyAttributes {
  public static final AttributeKey<String> USERNAME =
    AttributeKey.valueOf(MyAttributes.class, "USERNAME");
}

class MyAnnotatedService {

  @Get("/hello")
  public HttpResponse hello(
    @Attribute(prefix = MyAttributes.class, value = "USERNAME") // 👈👈👈
    String username) { ... }
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now specify a graceful shutdown timeout for an HTTP/2 connection in `}<a parentName="p" {...{
            "href": "type://ClientFactory:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientFactory.html"
          }}>{`type://ClientFactory`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5470"
          }}>{`#5470`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5489"
          }}>{`#5489`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ClientFactory
  .builder()
  .http2GracefulShutdownTimeoutMillis(1000)
  .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now specify an `}<a parentName="p" {...{
            "href": "type://EndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/EndpointGroup.html"
          }}>{`type://EndpointGroup`}</a>{` when building a `}<a parentName="p" {...{
            "href": "type://ClientRequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientRequestContext.html"
          }}>{`type://ClientRequestContext`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5292"
          }}>{`#5292`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5298"
          }}>{`#5298`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ClientRequestContext ctx =
  ClientRequestContext
    .builder(request)
    .endpointGroup(endpointGroup)
    .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now set a `}<inlineCode parentName="p">{`GraphQL`}</inlineCode>{` instance directly to the `}<a parentName="p" {...{
            "href": "type://GraphqlServiceBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/graphql/GraphqlServiceBuilder.html"
          }}>{`type://GraphqlServiceBuilder`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5269"
          }}>{`#5269`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`GraphQL graphQL = new GraphQL.Builder(graphqlSchema).build();
GraphqlServiceBuilder builder = ...
builder.graphql(graphQL);
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now specify a delay to close an HTTP/1.1 connection from the server side, allowing the client an
opportunity for active closing. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4849"
          }}>{`#4849`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5616"
          }}>{`#5616`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now configure the maximum length of a TLS client hello message that a server allows by using the
`}<inlineCode parentName="p">{`-Dcom.linecorp.armeria.defaultMaxClientHelloLength=<integer>`}</inlineCode>{` JVM option. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5747"
          }}>{`#5747`}</a></p>
      </li>
    </ul>
    <h2 {...{
      "id": "-improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-improvements",
        "aria-label": " improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📈 Improvements`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://XdsEndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/xds/client/endpoint/XdsEndpointGroup.html"
        }}>{`type://XdsEndpointGroup`}</a>{` now supports `}<a parentName="li" {...{
          "href": "https://www.envoyproxy.io/docs/envoy/latest/intro/arch_overview/upstream/load_balancing/slow_start"
        }}>{`Slow start mode`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5688"
        }}>{`#5688`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5693"
        }}>{`#5693`}</a></li>
      <li parentName="ul">{`Priority and locality based load balancing is now available for an `}<a parentName="li" {...{
          "href": "type://XdsEndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/xds/client/endpoint/XdsEndpointGroup.html"
        }}>{`type://XdsEndpointGroup`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5610"
        }}>{`#5610`}</a></li>
      <li parentName="ul">{`You can now view connection related information in the request logs. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5730"
        }}>{`#5730`}</a></li>
      <li parentName="ul">{`CORS headers are added to responses even if an exception is raised while handling a request. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5632"
        }}>{`#5632`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://AnnotatedService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/AnnotatedService.html"
        }}>{`type://AnnotatedService`}</a>{` is now available through a public API. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5382"
        }}>{`#5382`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5628"
        }}>{`#5628`}</a></li>
      <li parentName="ul">{`The metrics for failed requests while retrying now include the cause of the failure. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5405"
        }}>{`#5405`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5583"
        }}>{`#5583`}</a></li>
      <li parentName="ul">{`The `}<a parentName="li" {...{
          "href": "type://RequestLog:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/RequestLog.html"
        }}>{`type://RequestLog`}</a>{` from a retrying client now includes the current attempt number. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5716"
        }}>{`#5716`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5719"
        }}>{`#5719`}</a></li>
      <li parentName="ul">{`A colon is now allowed in the path for binding a service. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4577"
        }}>{`#4577`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5676"
        }}>{`#5676`}</a></li>
      <li parentName="ul">{`Timer histogram metrics now repects user-provided `}<inlineCode parentName="li">{`serviceLevelObjectives`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5661"
        }}>{`#5661`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://ServiceErrorHandler#renderStatus(ServiceRequestContext,RequestHeaders,HttpStatus,String,Throwable):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceErrorHandler.html#renderStatus(com.linecorp.armeria.server.ServiceRequestContext,com.linecorp.armeria.common.RequestHeaders,com.linecorp.armeria.common.HttpStatus,java.lang.String,java.lang.Throwable)"
        }}>{`type://ServiceErrorHandler#renderStatus(ServiceRequestContext,RequestHeaders,HttpStatus,String,Throwable)`}</a>{`
can now access the current `}<a parentName="li" {...{
          "href": "type://ServiceRequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceRequestContext.html"
        }}>{`type://ServiceRequestContext`}</a>{` if available. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5634"
        }}>{`#5634`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://SamlSingleSignOnHandler#loginFailed(ServiceRequestContext,AggregatedHttpRequest,MessageContext,Throwable):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/saml/SamlSingleSignOnHandler.html#loginFailed(com.linecorp.armeria.server.ServiceRequestContext,com.linecorp.armeria.common.AggregatedHttpRequest,org.opensaml.messaging.context.MessageContext,java.lang.Throwable)"
        }}>{`type://SamlSingleSignOnHandler#loginFailed(ServiceRequestContext,AggregatedHttpRequest,MessageContext,Throwable)`}</a>{`
can now access the `}<a parentName="li" {...{
          "href": "type://MessageContext"
        }}>{`type://MessageContext`}</a>{` for error handling if available. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5401"
        }}>{`#5401`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5622"
        }}>{`#5622`}</a></li>
      <li parentName="ul">{`The copied cURL command from a doc service, is now quoted correctly. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5566"
        }}>{`#5566`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-bug-fixes",
        "aria-label": "️ bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🛠️ Bug fixes`}</h2>
    <ul>
      <li parentName="ul">{`Fixed a bug where `}<a parentName="li" {...{
          "href": "type://RetryingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryingClient.html"
        }}>{`type://RetryingClient`}</a>{` gets to deadlock when `}<a parentName="li" {...{
          "href": "type://OAuth2Client:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/auth/oauth2/OAuth2Client.html"
        }}>{`type://OAuth2Client`}</a>{` fails to obtain
an access token. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5715"
        }}>{`#5715`}</a></li>
      <li parentName="ul">{`Armeria client always uses HTTP/2 connection preface for `}<inlineCode parentName="li">{`h2c`}</inlineCode>{`, regardless of
the value of `}<a parentName="li" {...{
          "href": "type://ClientFactoryOptions#useHttp2Preface():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientFactoryOptions.html#useHttp2Preface()"
        }}>{`type://ClientFactoryOptions#useHttp2Preface()`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5706"
        }}>{`#5706`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://ArmeriaServerConfigurator:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/spring/ArmeriaServerConfigurator.html"
        }}>{`type://ArmeriaServerConfigurator`}</a>{` now properly overrides the properties set by `}<a parentName="li" {...{
          "href": "type://ArmeriaSettings:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/spring/ArmeriaSettings.html"
        }}>{`type://ArmeriaSettings`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5009"
        }}>{`#5009`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5692"
        }}>{`#5692`}</a></li>
      <li parentName="ul">{`Armeria server now returns a 408 status if a service didn't receive the request fully and
the request times out. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5579"
        }}>{`#5579`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5680"
        }}>{`#5680`}</a></li>
      <li parentName="ul">{`A `}<inlineCode parentName="li">{`NullPointerException`}</inlineCode>{` isn't raised anymore when running Armeria with a self-signed certificate. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5669"
        }}>{`#5669`}</a>{`
It happened when the following conditions are all met:`}
        <ul parentName="li">
          <li parentName="ul">{`DEBUG-level logging of JDK security event is enabled.`}</li>
          <li parentName="ul">{`A user has no Bouncy Castle dependency.`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://@Description:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Description.html"
        }}>{`type://@Description`}</a>{` annotations at the super class or interface are now used for the parameter description. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5195"
        }}>{`#5195`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5562"
        }}>{`#5562`}</a></li>
      <li parentName="ul">{`The specfied `}<inlineCode parentName="li">{`ObjectMapper`}</inlineCode>{` to `}<a parentName="li" {...{
          "href": "type://WebClientRequestPreparation#asJson(Class,ObjectMapper):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/WebClientRequestPreparation.html#asJson(java.lang.Class,com.fasterxml.jackson.databind.ObjectMapper)"
        }}>{`type://WebClientRequestPreparation#asJson(Class,ObjectMapper)`}</a>{` isn't
ignored anymore. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5454"
        }}>{`#5454`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5512"
        }}>{`#5512`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://GrpcExceptionHandlerFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/grpc/GrpcExceptionHandlerFunction.html"
        }}>{`type://GrpcExceptionHandlerFunction`}</a>{` is now applied to convert a gRPC cancellations. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5329"
        }}>{`#5329`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5398"
        }}>{`#5398`}</a></li>
      <li parentName="ul">{`In Spring integration, the default `}<a parentName="li" {...{
          "href": "type://ClientFactory:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientFactory.html"
        }}>{`type://ClientFactory`}</a>{` is now gracefully closed after the server shuts down. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5742"
        }}>{`#5742`}</a></li>
      <li parentName="ul">{`A connection is not reused anymore after a `}<a parentName="li" {...{
          "href": "type://WriteTimeoutException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/WriteTimeoutException.html"
        }}>{`type://WriteTimeoutException`}</a>{` is raised. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5738"
        }}>{`#5738`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://ServiceRequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceRequestContext.html"
        }}>{`type://ServiceRequestContext`}</a>{` is now correctly propagated when
`}<a parentName="li" {...{
          "href": "type://ServerErrorHandler#onServiceException(ServiceRequestContext,Throwable):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerErrorHandler.html#onServiceException(com.linecorp.armeria.server.ServiceRequestContext,java.lang.Throwable)"
        }}>{`type://ServerErrorHandler#onServiceException(ServiceRequestContext,Throwable)`}</a>{` is invoked. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5746"
        }}>{`#5746`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-deprecations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-deprecations",
        "aria-label": "️ deprecations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🏚️ Deprecations`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://PrometheusMeterRegistries:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/metric/PrometheusMeterRegistries.html"
        }}>{`type://PrometheusMeterRegistries`}</a>{` and `}<a parentName="li" {...{
          "href": "type://PrometheusExpositionService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/metric/PrometheusExpositionService.html"
        }}>{`type://PrometheusExpositionService`}</a>{` are deprecated. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5698"
        }}>{`#5698`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Use the same classes in the `}<inlineCode parentName="li">{`armeria-prometheus1`}</inlineCode>{` module.`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://HttpResult:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/HttpResult.html"
        }}>{`type://HttpResult`}</a>{` is deprecated. Use `}<a parentName="li" {...{
          "href": "type://ResponseEntity:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/ResponseEntity.html"
        }}>{`type://ResponseEntity`}</a>{` instead. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4910"
        }}>{`#4910`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5586"
        }}>{`#5586`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://GraphqlConfigurator:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/graphql/GraphqlConfigurator.html"
        }}>{`type://GraphqlConfigurator`}</a>{` and various setter methods for building a `}<inlineCode parentName="li">{`GraphQL`}</inlineCode>{` instance are deprecated.`}
        <ul parentName="li">
          <li parentName="ul">{`Use `}<a parentName="li" {...{
              "href": "type://GraphqlServiceBuilder#graphql(GraphQL):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/graphql/GraphqlServiceBuilder.html#graphql(graphql.GraphQL)"
            }}>{`type://GraphqlServiceBuilder#graphql(GraphQL)`}</a>{` instead. `}<a parentName="li" {...{
              "href": "https://github.com/line/armeria/issues/5269"
            }}>{`#5269`}</a></li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "️-breaking-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-breaking-changes",
        "aria-label": "️ breaking changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`☢️ Breaking changes`}</h2>
    <ul>
      <li parentName="ul">{`We updated Micrometer to 1.13.0 that has breaking changes in its Prometheus support. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5698"
        }}>{`#5698`}</a>
        <ul parentName="li">
          <li parentName="ul">{`If you want to keep the old behavior that uses Prometheus Java client 0.x,
use the `}<inlineCode parentName="li">{`io.micrometer:micrometer-registry-prometheus-simpleclient:1.13.0`}</inlineCode>{` module.`}</li>
          <li parentName="ul">{`If you want to use Prometheus Java client 1.x, add `}<inlineCode parentName="li">{`com.linecorp.armeria:armeria-prometheus1`}</inlineCode>{` module.`}</li>
          <li parentName="ul">{`More details can be found in the `}<a parentName="li" {...{
              "href": "https://github.com/micrometer-metrics/micrometer/wiki/1.13-Migration-Guide"
            }}>{`Micrometer migration guide`}</a>{`.`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://AbstractEndpointSelector#updateNewEndpoints(List):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/AbstractEndpointSelector.html#updateNewEndpoints(java.util.List)"
        }}>{`type://AbstractEndpointSelector#updateNewEndpoints(List)`}</a>{` now returns a `}<inlineCode parentName="li">{`CompletableFuture`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5752"
        }}>{`#5752`}</a></li>
      <li parentName="ul">{`The following builder classes now have the `}<inlineCode parentName="li">{`SELF`}</inlineCode>{` type parameter. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5733"
        }}>{`#5733`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "type://AbstractDnsResolverBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/AbstractDnsResolverBuilder.html"
            }}>{`type://AbstractDnsResolverBuilder`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "type://AbstractRuleBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/AbstractRuleBuilder.html"
            }}>{`type://AbstractRuleBuilder`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "type://AbstractRuleWithContentBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/AbstractRuleWithContentBuilder.html"
            }}>{`type://AbstractRuleWithContentBuilder`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "type://AbstractCircuitBreakerMappingBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/AbstractCircuitBreakerMappingBuilder.html"
            }}>{`type://AbstractCircuitBreakerMappingBuilder`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "type://AbstractDynamicEndpointGroupBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/AbstractDynamicEndpointGroupBuilder.html"
            }}>{`type://AbstractDynamicEndpointGroupBuilder`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "type://DynamicEndpointGroupSetters:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/DynamicEndpointGroupSetters.html"
            }}>{`type://DynamicEndpointGroupSetters`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "type://AbstractCuratorFrameworkBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/zookeeper/AbstractCuratorFrameworkBuilder.html"
            }}>{`type://AbstractCuratorFrameworkBuilder`}</a></li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "-dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-dependencies",
        "aria-label": " dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`⛓ Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Blockhound 1.0.8.RELEASE → 1.0.9.RELEASE`}</li>
      <li parentName="ul">{`Control Plane 1.0.44 → 1.0.45`}</li>
      <li parentName="ul">{`GraphQL Kotlin 7.0.2 → 7.1.1`}</li>
      <li parentName="ul">{`gRPC Java 1.63.0 → 1.64.0`}</li>
      <li parentName="ul">{`Jackson 2.17.0 → 2.17.1`}</li>
      <li parentName="ul">{`Kotlin Coroutine 1.8.0 → 1.8.1`}</li>
      <li parentName="ul">{`Kubernetes client 6.11.0 → 6.12.1`}</li>
      <li parentName="ul">{`Mircometer 1.12.4 → 1.13.0`}</li>
      <li parentName="ul">{`Netty 4.1.108.Final → 4.1.110.Final`}</li>
      <li parentName="ul">{`Reactor 3.6.4 → 3.6.6`}</li>
      <li parentName="ul">{`Scala2.13 2.13.13 → 2.13.14`}</li>
      <li parentName="ul">{`Scala Collection compat 2.11.0 → 2.12.0 `}</li>
      <li parentName="ul">{`Spring 6.1.5 → 6.1.8`}</li>
      <li parentName="ul">{`Spring Boot 3.2.4 → 3.3.0`}</li>
    </ul>
    <h2 {...{
      "id": "-thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-thank-you",
        "aria-label": " thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙇 Thank you`}</h2>
    <ThankYou usernames={['ahnyujin', 'Be-poz', 'Dogacel', 'ChangguHan', 'chickenchickenlove', 'cj848', 'dachshu', 'efaurie', 'HappyHacker123', 'ikhoon', 'imasahiro', 'injae-kim', 'jaeseung-bae', 'jrhee17', 'KarboniteKream', 'ks-yim', 'Leewongi0731', 'minwoox', 'moromin', 'my4-dev', 'pinest94', 'ribafish', 'runningcode', 'sato9818', 'seonWKim', 'seongbeenkim', 'SeungWook-Han', 'sh-cho', 'sjy982', 'taisey', 'trustin', 'YangSiJun528', 'yeojin-dev']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      